import Vue from 'vue';

import {
  extend,
  ValidationObserver,
  ValidationProvider
} from 'vee-validate';

import {
  email,
  max,
  min,
  min_value,
  double,
  numeric,
  required,
  alpha_num,
  integer,
  regex
} from 'vee-validate/dist/rules';

import '@/validation/rules';

extend('email', email);
extend('max', max);
extend('min', min);
extend('min_value', min_value);
extend('numeric', numeric);
extend('double', double);
extend('required', required);
extend('alpha_num', alpha_num);
extend('integer', integer);
extend('regex', regex);

Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);
